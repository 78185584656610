<template>
	<div>
		<div class="row align-items-center justify-content-center">
			<h6 class="h6 text-secondary">Filtrar por rango de fechas &nbsp;&nbsp;</h6>
			<div class="col-auto text-center border border-primary rounded-lg">
				<a class="nav-link" data-toggle="dropdown" href="#">
					{{ desde + ' - ' + hasta }} <i class="bi bi-calendar-check"></i>
				</a>
				<div class="dropdown-menu dropdown-menu-sm text-center dropdown-menu-center">
					<a href="#" class="dropdown-item" @click="today('full')">
						<div class="media">
							<div class="media-body">
								<h3 class="dropdown-item-title">
									Hoy
								</h3>
							</div>
						</div>
					</a>
					<div class="dropdown-divider"></div>
					<a href="#" class="dropdown-item" @click="yesterday('full')">
						<div class="media">
							<div class="media-body">
								<h3 class="dropdown-item-title">
									Ayer
								</h3>
							</div>
						</div>
					</a>
					<div class="dropdown-divider"></div>
					<a href="#" class="dropdown-item" @click="sevenDay('full')">
						<div class="media">
							<div class="media-body">
								<h3 class="dropdown-item-title">
									Últimos 7 Días
								</h3>
							</div>
						</div>
					</a>
					<div class="dropdown-divider"></div>
					<a href="#" class="dropdown-item" @click="thirtyDay('full')">
						<div class="media">
							<div class="media-body">
								<h3 class="dropdown-item-title">
									Últimos 30 Días
								</h3>
							</div>
						</div>
					</a>
					<div class="dropdown-divider"></div>
					<a href="#" class="dropdown-item" @click="currentMonth('full')">
						<div class="media">
							<div class="media-body">
								<h3 class="dropdown-item-title">
									Mes Actual
								</h3>
							</div>
						</div>
					</a>
					<div class="dropdown-divider"></div>
					<a href="#" class="dropdown-item" @click="lastMonth('full')">
						<div class="media">
							<div class="media-body">
								<h3 class="dropdown-item-title">
									Mes anterior
								</h3>
							</div>
						</div>
					</a>
					<div class="dropdown-divider"></div>
					<a href="#" class="dropdown-item">
						<h3 class="dropdown-item-title">
							<div class="row">
								<div class="col">
									<VueDatePicker v-model="fechaDesde" @onChange="tablesearch" format="DD-MM-YY"
										no-header>
									</VueDatePicker>
								</div>
								<div class="col">
									<VueDatePicker v-model="fechaHasta" @onChange="tablesearch" format="DD-MM-YY"
										no-header>
									</VueDatePicker>
								</div>
							</div>
						</h3>
					</a>
				</div>
			</div>
			<div class="col-auto text-center m-1">
				<button class="btn btn-warning" @click="today('full')"><b><i
							class="bi bi-arrow-repeat"></i>&nbsp;&nbsp;Reiniciar&nbsp;Busqueda</b></button>
			</div>
		</div>
	</div>
</template>
<script>
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import 'moment/locale/es';

export default {
	props: ['typevisita'],
	name: "disabled_dates",
	components: {
		VueDatePicker
	},
	data: function () {
		return {
			desde: null,
			hasta: null,
		}
	},

	created() {
		if (this.fechaDesde === null || this.fechaHasta === null) {
		this.fechaDesde = moment().format('YYYY-MM-DD');
		this.fechaHasta = moment().format('YYYY-MM-DD');
		this.desde = moment().format('L');
		this.hasta = moment().format('L');
		}else{
			this.desde = this.desde;
			this.hasta = this.hasta;
			this.fechaDesde = this.fechaDesde;
			this.fechaHasta = this.fechaHasta;
		}
	},
	computed: {
		fechaDesde: {
			// getter
			get: function () {
				return this.$store.getters['fechavisitas/desde'];
			},
			// setter
			set: function (newValue) {
				this.$store.commit('fechavisitas/setDesde', newValue);
				this.desde = newValue[8] + newValue[9] + '/' + newValue[5] + newValue[6] + '/' + newValue[0] + newValue[1] + newValue[2] + newValue[3];
			}
		},
		fechaHasta: {
			// getter
			get: function () {
				return this.$store.getters['fechavisitas/hasta']
			},
			// setter
			set: function (newValue) {
				this.$store.commit('fechavisitas/setHasta', newValue)
				this.hasta = newValue[8] + newValue[9] + '/' + newValue[5] + newValue[6] + '/' + newValue[0] + newValue[1] + newValue[2] + newValue[3];
			}
		},
	},
	methods: {
		tablesearch(event) {
			$('#table_id').DataTable().draw();
		},
		today(type) {
			this.desde = moment().format('L');
			this.hasta = moment().format('L');
			this.$store.commit('fechavisitas/setDesde', this.fechaDesde = moment().format('YYYY-MM-DD'));
			this.$store.commit('fechavisitas/setHasta', this.fechahasta = moment().format('YYYY-MM-DD'));
			if (type == 'full') {
				$('#table_id').DataTable().draw();
			}
		},
		yesterday(type) {
			this.desde = moment().subtract(1, 'days').format('L');
			this.hasta = moment().subtract(1, 'days').format('L');
			this.$store.commit('fechavisitas/setDesde', this.fechaDesde = moment().subtract(1, 'days').format('YYYY-MM-DD'))
			this.$store.commit('fechavisitas/setHasta', this.fechaHasta = moment().subtract(1, 'days').format('YYYY-MM-DD'))
			if (type == 'full') {
				$('#table_id').DataTable().draw();
			}
		},
		sevenDay(type) {
			this.desde = moment().subtract(6, 'days').format('L');
			this.hasta = moment().format('L');
			this.$store.commit('fechavisitas/setDesde', this.fechaDesde = moment().subtract(6, 'days').format('YYYY-MM-DD'))
			this.$store.commit('fechavisitas/setHasta', this.fechaHasta = moment().format('YYYY-MM-DD'))
			if (type == 'full') {
				$('#table_id').DataTable().draw();
			}
		},
		thirtyDay(type) {
			this.desde = moment().subtract(29, 'days').format('L');
			this.hasta = moment().format('L');
			this.$store.commit('fechavisitas/setDesde', this.fechaDesde = moment().subtract(29, 'days').format('YYYY-MM-DD'))
			this.$store.commit('fechavisitas/setHasta', this.fechaHasta = moment().format('YYYY-MM-DD'))
			if (type == 'full') {
				$('#table_id').DataTable().draw();
			}
		},
		currentMonth(type) {
			this.desde = moment().startOf('month').format('L');
			this.hasta = moment().endOf('month').format('L');
			this.$store.commit('fechavisitas/setDesde', this.fechaDesde = moment().startOf('month').format('YYYY-MM-DD'))
			this.$store.commit('fechavisitas/setHasta', this.fechaHasta = moment().endOf('month').format('YYYY-MM-DD'))
			if (type == 'full') {
				$('#table_id').DataTable().draw();
			}
		},
		lastMonth(type) {
			this.desde = moment().subtract(1, 'month').startOf('month').format('L');
			this.hasta = moment().subtract(1, 'month').endOf('month').format('L');
			this.$store.commit('fechavisitas/setDesde', this.fechaDesde = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'))
			this.$store.commit('fechavisitas/setHasta', this.fechaHasta = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'))
			if (type == 'full') {
				$('#table_id').DataTable().draw();
			}
		},

	}
}
</script>
