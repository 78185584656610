<template>
	<div>
		<form-modal :tipe="tipeform" :urlform="urlform" :tipemessage="tipemessage" :form="form" :key="componentKey"
			:showModal="showModal" />
		<input type="text" :value="form.apiStatus" @input="$emit('apiStatus', $event.target.value)" class="form-control"
			hidden>
		<div class="row g-3">
			<div class="col-md-3">
				<label for="" class="text-uppercase">Tipo documento</label>
				<div class="input-group">
					<select name="tipo" class="form-control jsoninput" data-name="tipo" data-live-search="false"
						@change="jsonform">
						<option v-for="item in tipos" :key="item.id" :data-name="item.tipo" :data-id="item.id"
							:selected="item.id == jsonselects.tipo.id">
							{{ item.tipo }}
						</option>
					</select>
					<input type="text" ref="dni" id="dni" placeholder="Identificación" class="form-control"
						@keydown="ValidarDigitos" v-on:keyup.13="frmApiReniec" :value="form.dni"
						@input="$emit('dni', $event.target.value)"
						onKeypress="if (event.keyCode < 48 || event.keyCode > 57) event.returnValue = false;"
						style="text-transform: Capitalize;">

					<span v-if="tipoDocumento" class="input-group-append">
						<a v-if="btnBuscarReniec" id="btnBuscar" href="#" class="btn">
							<span class="spinner-border spinner-border-sm"></span>
							<span class="visually-hidden">Buscando..</span></a>
						<a v-else id="btnBuscar" href="#" @click.prevent="frmApiReniec" class="btn btn-info btn-flat">
							<i class="bi bi-search"></i>&nbsp;Buscar</a>
					</span>
				</div>
				<ul>
					<li class="text-danger" v-if="errors && errors.dni"><small>{{ errors.dni[0] }}</small></li>
				</ul>
			</div>
			<div class="col-md-4">
				<label for="" class="text-uppercase">Nombre y Apellido</label>
				<input type="text" placeholder="" ref="nombres" id="nombres" class="form-control rounded-lg"
					:value="form.nombre" @input="$emit('nombre', $event.target.value)">
			</div>
			<div class="col-md-2">
				<label for="" class="text-uppercase">Tipo visita</label>
				<div class="input-group">
					<select name="tipovisita" class="form-control jsoninput" data-name="tipovisita"
						data-live-search="false" @change="jsonform">
						<option value="">Tipo de visita...</option>
						<option v-for="item in tipovisitas" :key="item.id" :data-name="item.nombre" :data-id="item.id"
							:selected="item.id == jsonselects.tipovisita.id">
							{{ item.nombre }}
						</option>
					</select>
				</div>
				<ul>
					<li class="text-danger" v-if="errors && errors.tipovisita"><small>{{ errors.tipovisita[0] }}</small>
					</li>
				</ul>
			</div>
			<div class="col-md-3">
				<label for="" class="text-uppercase">Entidad</label>
				<div class="input-group">
					<select class="form-control jsoninput" id="entidad" data-name="entidad"
						data-live-search-normalize="true" data-live-search="true" @change="jsonform">
						<option value="">Seleccionar Entidad...</option>
						<option v-for="item in entidad" :key="item.id" :data-name="item.nsocial" :data-id="item.id"
							:selected="item.id == jsonselects.entidad.id">
							{{ item.nsocial }}
						</option>
					</select>
					<span class="input-group-append">
						<a href="#" @click.prevent="createform" class="btn btn-info btn-flat">
							<i class="bi bi-plus-square"></i></a>
					</span>
				</div>
			</div>
		</div>
		<hr>
		<div class="row g-3 pb-3">
			<div class="col-md-4">
				<label for="" class="text-uppercase">Motivo</label>
				<select name="motivo" class="form-control jsoninput" data-name="motivo"
					data-live-search-normalize="true" data-live-search="true" @change="jsonform">
					<option value="">Seleccionar Motivo...</option>
					<option v-for="item in motivos" :key="item.id" :data-name="item.motivo" :data-id="item.id"
						:selected="item.id == jsonselects.motivo.id">
						{{ item.motivo }}
					</option>
				</select>
			</div>
			<div class="col-md-4">
				<label for="" class="text-uppercase">Empleado</label>
				<select name="empleado" id="empleado" class="form-control jsoninput" data-name="empleado"
					data-live-search-normalize="true" data-live-search="true" @change="jsonform">
					<option value="">Seleccionar Empleado...</option>
					<option v-for="item in empleados" :key="item.id" :data-name="item.empleado" :data-id="item.id"
						:data-emp="item.itemsjson" :selected="item.id == jsonselects.empleado.id">
						{{ item.empleado }}
					</option>
				</select>
			</div>
			<div class="col-md-4">
				<label for="" class="text-uppercase">Oficina - Cargo</label>
				<input type="text" placeholder="" class="form-control rounded-lg" :value="jsonselects.empleado.emp"
					@input="$emit('nombre', $event.target.value)" readonly>
			</div>
		</div>
		<hr>
		<div class="row g-3 pb-3">
			<div class="col-md-2">
				<label for="" class="text-uppercase">Celular:</label>
				<input type="text" :value="form.contacto" @input="$emit('contacto', $event.target.value)"
					class="form-control" placeholder="Contacto">
			</div>
			<div class="col-md-3">
				<label for="" class="text-uppercase">Sede</label>
				<select name="sede" class="form-control jsoninput" data-name="sede" data-live-search-normalize="true"
					data-live-search="true" @change="jsonform">
					<option value="">Seleccionar Sede...</option>
					<option v-for="item in sedes" :key="item.id" :data-name="item.sede" :data-id="item.id"
						:selected="item.id == 1">
						{{ item.sede }}
					</option>
				</select>
			</div>
			<div class="col-md-3">
				<label for="" class="text-uppercase">Lugar de reunión</label>
				<select name="oficina" id="" class="form-control jsoninput" data-dropup-auto="false" data-name="oficina"
					data-live-search-normalize="true" data-live-search="true" @change="jsonform">
					<option value="">Seleccionar Oficina...</option>
					<option v-for="item in oficinas" :key="item.id" :data-name="item.oficina" :data-id="item.id"
						:selected="item.id == jsonselects.oficina.id">
						{{ item.oficina }}
					</option>
				</select>
			</div>
			<div class="col-sm-7 col-md-2">
				<label for="FechaRecibida" class="text-uppercase">Fecha:</label>
				<div class="form-control rounded-lg">
					<VueDatePicker v-model="form.fecha_programada" format-header="dddd, DD MMMM" no-header>

					</VueDatePicker>
				</div>
			</div>
			<div class="col-sm-5 col-md-2 mt-sm-0">
				<label for="hora_entrada" class="text-uppercase">Hora: </label>
				<input type="time" id="hora_entrada" name="hora_entrada" class="form-control rounded-lg"
					v-model="form.hora_entrada" />
			</div>
		</div>
		<hr>
		{{ info }}
	</div>
</template>

<script>

import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import FormModal from '@/components/visitas/AddEntidad.vue';

let user = document.head.querySelector('meta[name="usuario"]');

export default {
	components: {
		VueDatePicker, FormModal
	},
	props: ['form', 'type'],

	data: function () {
		return {
			info: null,
			usuarios: null,
			errors: {},
			id: null,
			loading: '',
			entidad: null,
			motivos: null,
			empleados: null,
			sedes: null,
			oficinas: null,
			usuario: null,
			tipovisitas: null,
			btnBuscarReniec: false,
			btnBuscarSunat: false,
			tipos: null,
			urlform: null,
			tipeform: null,
			tipemessage: null,
			showModal: false,
			componentKey: 0,

			tipoDocumento: true,
			cantidadDigitos: 8,

			corregirDatos: null,
		}
	},

	mounted() {
		this.getSelects();
		this.$refs.dni.focus();
		document.getElementById("nombres").disabled = true;
		this.form.itemsjson.usuario.id = JSON.parse(user.content).id;
		this.form.itemsjson.usuario.name = JSON.parse(user.content).name;
		this.form.hora_entrada = moment(moment(), 'HH:mm:ss').format('HH:mm');
	},
	methods: {
		async getSelects() {
			try {
				const response = await axios.get(route('visitas.selects'));
				this.entidad = response.data.entidad;
				this.motivos = response.data.motivos;
				this.empleados = response.data.empleados;
				this.sedes = response.data.sedes;
				this.oficinas = response.data.oficinas;
				this.tipovisitas = response.data.tipovisitas;
				this.tipos = [{ "id": 1, "tipo": "DNI" }, { "id": 2, "tipo": "CE" }, { "id": 3, "tipo": "PASAPORTE" }];
			} catch (error) {
				console.error(error);
			}
		},
		jsonform(event) {

			let nameinput = $(event.currentTarget).attr('data-name');

			if (nameinput == 'empleado') {
				this.form.itemsjson[nameinput] = {
					id: $(event.currentTarget).find(':selected').attr('data-id'),
					name: $(event.currentTarget).find(':selected').attr('data-name'),
					emp: $(event.currentTarget).find(':selected').attr('data-emp'),
				}
				const json = this.jsonselects.empleado.emp;
				const obj = JSON.parse(json);
				this.jsonselects.empleado.emp = obj.oficina.name + ' - ' + obj.cargo.name;
			} else {
				this.form.itemsjson[nameinput] = {
					id: $(event.currentTarget).find(':selected').attr('data-id'),
					name: $(event.currentTarget).find(':selected').attr('data-name'),
				}
			}

			if (nameinput === 'tipo') {
				switch (this.jsonselects.tipo.id) {
					case '1':
						document.getElementById("nombres").disabled = true;
						this.tipoDocumento = true;
						this.cantidadDigitos = 8;
						this.form.nombre = '';
						this.$refs.dni.focus();
						break;
					case '2':
						document.getElementById("nombres").disabled = false;
						this.tipoDocumento = false;
						this.cantidadDigitos = 12;
						this.form.nombre = '';
						this.$refs.dni.focus();
						break;
					case '3':
						document.getElementById("nombres").disabled = false;
						this.tipoDocumento = false;
						this.cantidadDigitos = 12;
						this.form.nombre = '';
						this.$refs.dni.focus();
						break;
				}
			}

			if (nameinput === 'tipovisita') {
				switch (this.jsonselects.tipovisita.id) {
					case '1':
						$('#entidad').selectpicker('val', 'PÚBLICO EN GENERAL');
						var identidad = 0;
						var nameentidad = '-';
						for (var i = 0; i < this.entidad.length; i++) {
							if (this.entidad[i].nsocial === $('#entidad').selectpicker('val')) {
								identidad = this.entidad[i].id;
								nameentidad = this.entidad[i].nsocial;
							}
						}
						this.form.itemsjson['entidad'] = {
							id: identidad,
							name: nameentidad,
						}

						$('#empleado').selectpicker('val', 'A VARIOS');
						var idemp = 0;
						var nameemp = '';
						for (var i = 0; i < this.empleados.length; i++) {
							if (this.empleados[i].empleado === $('#empleado').selectpicker('val')) {
								idemp = this.empleados[i].id;
								nameemp = this.empleados[i].empleado;
							}
						}

						this.form.itemsjson['empleado'] = {
							id: idemp,
							name: nameemp,
							emp: '- - -',
						}
						this.jsonselects.empleado.emp = '- - -';
						break;
					case '2':
						$('#entidad').selectpicker('val', 'PÚBLICO EN GENERAL');
						var identidad = 0;
						var nameentidad = '-';
						for (var i = 0; i < this.entidad.length; i++) {
							if (this.entidad[i].nsocial === $('#entidad').selectpicker('val')) {
								identidad = this.entidad[i].id;
								nameentidad = this.entidad[i].nsocial;
							}
						}
						this.form.itemsjson['entidad'] = {
							id: identidad,
							name: nameentidad,
						}

						$('#empleado').selectpicker('val', 'A VARIOS');
						var idemp = 0;
						var nameemp = '';
						for (var i = 0; i < this.empleados.length; i++) {
							if (this.empleados[i].empleado === $('#empleado').selectpicker('val')) {
								idemp = this.empleados[i].id;
								nameemp = this.empleados[i].empleado;
							}
						}

						this.form.itemsjson['empleado'] = {
							id: idemp,
							name: nameemp,
							emp: '- - -',
						}
						this.jsonselects.empleado.emp = '- - -';
						break;
					case '3':
						break;
					case '4':

						break;
					case '5':

						break;
				}
			}
		},
		frmApiReniec() {
			this.btnBuscarReniec = true;
			this.form.nombre = '';
			// const apiReniec = "http://172.16.100.18:80/api/consulta-reniec";
			//const apiReniec = "/reniec";
			const params = {
				dni: this.form.dni,
			};
			axios.post(route('reniec'), params).then(response => {
				if (response.data.nombres == '') {
					swal.fire({
						title: 'Consulta Reniec',
						text: "No se encontraron resultados",
						icon: 'error',
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						didClose: (e) => {
							this.btnBuscarReniec = false;
							document.getElementById("nombres").disabled = false;
							this.$refs.nombres.focus();
						}
					});
					this.form.apiStatus = 0;
					return;
				}

				const reniec = response.data;
				this.form.nombre = reniec.nombres;
				this.btnBuscarReniec = false;
				this.errors = {};
				this.selectDatosEr();
			}).catch(error => {
				if (error.response.status === 422) {
					this.errors = error.response.data.errors;
					this.$refs.dni.focus();
					this.btnBuscarReniec = false;
				} else if (error.response.status == 500) {
					this.form.apiStatus = 0;
					swal.fire({
						title: 'Error con la conexión a RENIEC',
						text: "¡Por favor!, llenar los datos manualmente.",
						icon: 'error',
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						didClose: (e) => {
							this.btnBuscarReniec = false;
							document.getElementById("nombres").disabled = false;
							this.$refs.nombres.focus();
						},
						confirmButtonText: 'Aceptar!',
					}).then((result) => {
						if (result.isConfirmed) {
							this.btnBuscarReniec = false;
							document.getElementById("nombres").disabled = false;
						}
					});
				} else {
					this.form.apiStatus = 0;
					swal.fire({
						title: 'Error con la conexión a RENIEC',
						text: "¡Por favor!, llenar los datos manualmente.",
						icon: 'error',
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						didClose: (e) => {
							this.btnBuscarReniec = false;
							document.getElementById("nombres").disabled = false;
							this.$refs.nombres.focus();
						},
						confirmButtonText: 'Aceptar!',
					}).then((result) => {
						if (result.isConfirmed) {
							this.btnBuscarReniec = false;
							document.getElementById("nombres").disabled = false;
						}
					});
				}
			}).finally(() => this.loading = false);

		},
		createform() {
			this.AddEntidad = {
				nombres: '',
				apellidos: '',
				nsocial: '',
				identificacion: '',
				direccion: ''
			}
			this.urlform = route('entidad.save')
			this.tipeform = 'create'
			this.tipemessage = 'Crear Entidad'
			this.componentKey += 1
			this.showModal = true
		},
		ValidarDigitos() {
			var input = document.getElementById('dni');
			var cantidad = this.cantidadDigitos

			input.addEventListener('input', function () {
				if (this.form.dni.value.length > cantidad) {
					this.form.dni.value = this.form.dni.value.slice(0, cantidad);
				}
			})
		},
		async selectDatosEr() {
			try {
				const response = await axios.get(route('visitas.selectEr'));
				this.corregirDatos = response.data.datosErroneos;
				for (var i = 0; i < this.corregirDatos.length; i++) {
					this.corregirDatosEr(this.corregirDatos[i]);
				}
			} catch (error) {
				console.error(error);
			}
		},
		corregirDatosEr(datoEr) {
			const params = {
				dni: datoEr.dni,
			};
			axios.post(route('reniec'), params).then(response => {
				const datosReniec = response.data;
				datoEr.nombre = datosReniec.nombres;
				this.updateDatosErr(datoEr);
			});
		},
		updateDatosErr(datoCorrecto) {
			axios.post(route('visitas.updateEr'), datoCorrecto)
				.then(function (response) {
					console.log('Listo');
				})
				.catch(function (error) {
					loader.hide()
					if (error.response.status == 422) {
						console.log(error.response.data);
					} else if (error.response.status == 500) {
						console.log(error.response.data);
					}
				});
		}
	},
	computed: {
		jsonselects() {
			return this.form.itemsjson;
		},
	},

	watch: {
		entidad() {
			$(function () {
				setTimeout(function () {
					$('select').selectpicker('refresh');
				}, 300);
			})
		}
	},
}
</script>
