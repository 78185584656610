<template>
  <div class="container">
    <div class="row">
      <button @click="abrirTimePicker" class="col btn btn-success btn-sm">
        <slot>Marcar</slot>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['id', 'url_marcar', 'type'],
  methods: {
    abrirTimePicker() {
      const tipo = this.type === "entrada" ? "Entrada" : "Salida";
      const ahora = new Date();
      
      swal.fire({
        title: `Hora de ${tipo}`,
        html: `
          <div class="time-container">
            <input 
              type="time" 
              id="horaHtml"
              class="swal2-input"
              value="${ahora.toLocaleTimeString('en-US', { hour12: false }).slice(0, 5)}"
            >
          </div>
        `,
        showCancelButton: true,
        confirmButtonText: 'Guardar',
        preConfirm: () => {
          const timeValue = document.getElementById('horaHtml').value;
          const [horas, minutos] = timeValue.split(':');
          // Convertir a formato 24h
          let horas24 = parseInt(horas);

          return `${horas24.toString().padStart(2, '0')}:${minutos}:00`;
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.enviarHora(result.value);
        }
      });
    },

    enviarHora(hora) {
      axios.post(this.url_marcar, {
        id_data: this.id,
        type: this.type,
        hora: hora
      }).then(() => {
        $('#table_id').DataTable().ajax.reload();
        swal.fire('Hora de salida guardada!', '', 'success');
      }).catch((error) => {
        swal.fire('Error', error.response.data.message || 'Error', 'error');
      });
    }
  }
}
</script>

<style scoped>
.time-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.swal2-input {
  width: auto !important;
  flex: 1;
}
</style>